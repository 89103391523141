import React from 'react';
import ModelPage from './components/ModelPage';

function App() {
  const modelId = "1"; // Уникальный идентификатор модели
  const modelUrl = "https://sofiap.space/offer"; // Ваш основной URL для редиректа
  const onlyUrl = "sofiap.space/offer"; // Ваш URL для редиректа без протокола

  return (
    <div>
      <ModelPage modelId={modelId} modelUrl={modelUrl} onlyUrl={onlyUrl} />
    </div>
  );
}

export default App;
