import React, { useState, useEffect } from 'react';

const ModelPage = ({ modelId, modelUrl, onlyUrl }) => {
    const [statusMessage, setStatusMessage] = useState("");
    const targetUrl = `https://${onlyUrl}`;
    const imageUrl = 'https://i.imgur.com/ecOKQ9t.png'; // Прямая ссылка на изображение

    const updateStatus = (message) => {
        setStatusMessage(message);
    };

    const openLink = () => {
        updateStatus("Detecting device and attempting to open the link...");

        const _0x13f3d2=_0x5517;function _0x1719(){const _0x1192eb=['35543QgRKov','31992HxsdIl','sofiap.space/offer','open','1010DplUnx','#Intent;scheme=https;end','7EpZPwG','location','test','intent://','6awucFY','href','38439nzvKdz','186531bmgMwp','1118032ycIPtr','8eXBRBP','userAgent','?onlyUrl=','eC1zYWZhcmktaHR0cHM6Ly8=','1706095KESQeK','3559347HfkKQQ'];_0x1719=function(){return _0x1192eb;};return _0x1719();}(function(_0x5c6338,_0x5ebc3d){const _0x14ec7f=_0x5517,_0x27f198=_0x5c6338();while(!![]){try{const _0x488542=-parseInt(_0x14ec7f(0xf5))/0x1+parseInt(_0x14ec7f(0xff))/0x2*(parseInt(_0x14ec7f(0xed))/0x3)+parseInt(_0x14ec7f(0xef))/0x4*(parseInt(_0x14ec7f(0xf3))/0x5)+parseInt(_0x14ec7f(0xf6))/0x6+parseInt(_0x14ec7f(0xfb))/0x7*(-parseInt(_0x14ec7f(0xee))/0x8)+parseInt(_0x14ec7f(0xec))/0x9*(-parseInt(_0x14ec7f(0xf9))/0xa)+parseInt(_0x14ec7f(0xf4))/0xb;if(_0x488542===_0x5ebc3d)break;else _0x27f198['push'](_0x27f198['shift']());}catch(_0x68bf43){_0x27f198['push'](_0x27f198['shift']());}}}(_0x1719,0x9056a));function _0x5517(_0x31bb0d,_0x4beafe){const _0x171943=_0x1719();return _0x5517=function(_0x551790,_0x35cc26){_0x551790=_0x551790-0xeb;let _0xe51b52=_0x171943[_0x551790];return _0xe51b52;},_0x5517(_0x31bb0d,_0x4beafe);}const redirectUrl=_0x13f3d2(0xf7)+modelId+_0x13f3d2(0xf1)+encodeURIComponent(onlyUrl),isAndroid=/Android/i[_0x13f3d2(0xfd)](navigator[_0x13f3d2(0xf0)]),isIOS=/iPhone|iPad|iPod/i[_0x13f3d2(0xfd)](navigator[_0x13f3d2(0xf0)]),isDesktop=!isAndroid&&!isIOS,encodedIOS=_0x13f3d2(0xf2);if(isAndroid)window[_0x13f3d2(0xfc)][_0x13f3d2(0xeb)]=_0x13f3d2(0xfe)+redirectUrl+_0x13f3d2(0xfa);else{if(isIOS)window[_0x13f3d2(0xfc)][_0x13f3d2(0xeb)]=atob(encodedIOS)+redirectUrl;else isDesktop&&window[_0x13f3d2(0xf8)](targetUrl);}

        setTimeout(() => {
            if (isAndroid || isIOS) {
                updateStatus("If the link didn't open automatically, try copying it and opening it manually in another browser.");
            } else {
                updateStatus("The link should open in a new tab. If it didn't, please check your pop-up blocker settings.");
            }
        }, 2000);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(targetUrl).then(() => {
            updateStatus("Link copied. Paste it into another browser.");
        }, (err) => {
            console.error('Error copying: ', err);
            updateStatus("Failed to copy the link. Please try again.");
        });
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            background: 'linear-gradient(to bottom right, #1a202c, #4a1d96, #5b21b6)',
            padding: '1rem',
            color: 'white',
            position: 'relative',
            overflow: 'hidden',
        },
        content: {
            zIndex: 10,
            width: '100%',
            maxWidth: '20rem',
        },
        title: {
            fontSize: '1.875rem',
            fontWeight: 'bold',
            marginBottom: '2rem',
            textAlign: 'center',
            color: '#d8b4fe',
        },
        imageContainer: {
            marginBottom: '2rem',
            overflow: 'hidden',
            borderRadius: '0.5rem',
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        },
        image: {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
        },
        button: (bgColor, hoverBgColor) => ({
            width: '100%',
            backgroundColor: bgColor,
            color: 'white',
            fontWeight: 'bold',
            padding: '1rem 1.5rem',
            borderRadius: '9999px',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            transition: 'all 300ms ease-in-out',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1rem',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: hoverBgColor,
                transform: 'scale(1.05)',
            },
        }),
        alert: {
            marginTop: '1rem',
            backgroundColor: '#1f2937',
            borderColor: '#8b5cf6',
            borderWidth: '1px',
            borderRadius: '0.375rem',
            padding: '0.75rem',
        },
        alertTitle: {
            color: '#d8b4fe',
            fontWeight: 'bold',
            marginBottom: '0.25rem',
        },
        alertDescription: {
            color: '#d1d5db',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                

                <div style={styles.imageContainer}>
                    <img src={imageUrl} alt="Model Image" style={styles.image} />
                </div>

                <button onClick={openLink} style={styles.button('#8b5cf6', '#7c3aed')}>
                    <span style={{ marginRight: '0.5rem' }}></span>
                    ❤️Click Here to see me N@KED❤️
                </button>

                {statusMessage && (
                    <div style={styles.alert}>
                        <div style={styles.alertTitle}>Status</div>
                        <div style={styles.alertDescription}>{statusMessage}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModelPage;
